import React, { Component } from "react";
// import "antd/dist/antd.min.css";
import { Row, Col, Button, Card } from "antd";
import image1 from "../../Images/image1.jpg";
import image3 from "../../Images/image3.jpg";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7 from "../../Images/image7.png";
import LogoTransparency from "../../Images/LogoTransparency.png";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import video1 from "../../Components/Video/Vd1.mp4";

class Home extends Component {
  handleClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  openInNewTab1 = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  openInNewTab2 = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  render() {
    const { Footer } = Layout;

    // const list = [
    //   {
    //     description:
    //       "Create Your Profile: Kickstart your journey by completing a quick and easy questionnaire to build your online profile.",
    //     key: 0,
    //   },
    //   {
    //     description:
    //       "Optimal Matches: Our cutting-edge system works behind the scenes to connect your business with the most fitting marketing-media outlets, tailored to your business needs.",
    //     key: 1,
    //   },
    //   {
    //     description:
    //       "Immediate Match Alert: Receive an email notification featuring the top three marketing outlets carefully selected to fulfill the optimal promotional needs of your business.",
    //     key: 2,
    //   },
    //   {
    //     description:
    //       "Unlock the Possibilities: Once our algorithm identifies your top three matches, access a comprehensive list of marketing outlets ready to elevate your efforts for a nominal fee. Connect directly with the businesses or opt to have a representative reach out to you. Say hello to success with their contact information at your fingertips!",
    //     key: 3,
    //   },
    // ];

    return (
      <Layout>
        <Row style={{overflowX:"hidden"}}>
          <Row>
            <Col
              xl={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
            >
              <div
                className="image3"
                style={{
                  backgroundImage: `url(${image3})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h3
                  className="background-content"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Connecting businesses with <br /> marketing platforms that
                  effectively
                  <br />
                  promote their brand.
                  <br />
                </h3>

                {/* <Link to="/app/questionary" onClick={this.handleClick}>
                  <Button key="1" type="primary" className="find">
                    Register Now
                  </Button>
                </Link>  */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col              
              xxl={{ span: 20, offset: 1}}
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 24, offset: 0 }}
              md={{ span: 22, offset: 1 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <div className="media-home">
                <p className="point-head">
                  Are you a business owner ready to take your marketing to the
                  next level?{" "}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 14, offset: 0 }}
            >              
              <img src={image1} className="image1" alt="image1" />              
            </Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 9, offset: 0 }}
            >
              <div>
              <p className="para2-home">
                {" "}
                If the fear of choosing the wrong marketing platform to promote
                your business is holding you back, MediaPiq is here to assist!
                We connect your business with platforms tailored to your
                marketing needs, ensuring that your advertising dollars are well
                spent.
              </p>
              <p className="para3-home">
                {" "}
                Our algorithm matches your business needs with the most
                effective media platforms available in the area.
              </p>
              </div>
              <Link to="/app/questionary" onClick={this.handleClick}>
                  <Button key="1" type="primary" style={{borderRadius: '55px',height:'50px', width: '220px'}}>
                    Find Your Match
                  </Button>
                </Link> 
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 22, offset: 1 }}
              lg={{ span: 23, offset: 0 }}
              md={{ span: 19, offset: 4 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 19, offset: 5}}
            >
              <div className="media-home1">
                <p className="point-head2">
                  DISCOVER THE POWER OF PERSONALIZED MARKETING WITH
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={{ span: 1, offset: 10 }}
              xl={{ span: 12, offset: 1 }}
              lg={{ span: 6, offset: 10 }}
              md={{ span: 14, offset: 8 }}
              sm={{ span: 22, offset: 1 }}
              xs={{ span: 12, offset: 1 }}
            >
              <Link to="/">
                <div className="logoStyle">
                  <img
                    src={LogoTransparency}
                    className="logoImgStyle"
                    alt="uploaded"
                  />
                </div>
              </Link>
            </Col>
          </Row>
          <Row
            style={{ marginTop: "6vw", }}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col 
            xxl={{span:21,offset:3}}
            xl={{span:22,offset:1}}
            lg={{span:22,offset:1}}
            md={{span:22,offset:1}}
            >
            {/* <div className="cardDiv"> */}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={5}
                style={{ marginBottom: "20px" }}
              >
                <div className="cardStyle">
                  <Card className="custom-card">
                    <h2 className="titleStyle">
                      <b>CREATE YOUR PROFILE</b>
                    </h2>
                    <img
                      src={image6}
                      alt="img"
                     className="cardImg6"
                    />
                    <p className="descStyle">
                      Kickstart your journey by completing a quick and easy
                      questionnaire to build your online profile.
                    </p>
                    <a href='/app/questionary' class="register-link">
                     Register Now
                    </a>
                  </Card>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={5}
                style={{ marginBottom: "20px" }}
              >
                <div className="cardStyle">
                  <Card className="custom-card">
                    <h2 className="titleStyle">
                      <b>OPTIMAL MATCHES</b>
                    </h2>
                    <img
                      src={image7}
                      alt="img"
                      style={{ height: "300%", width: "100%",marginLeft:'-6px' }}
                    />
                    <p className="descStyle">
                      Our cutting-edge system works behind the scenes to connect
                      your business with the most fitting marketing-media
                      outlets, tailored to your business needs.
                    </p>
                  </Card>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={5}
                style={{ marginBottom: "20px" }}
              >
                <div className="cardStyle">
                  <Card className="custom-card">
                    <h2 className="titleStyle">
                      <b>IMMEDIATE MATCH ALERT</b>
                    </h2>
                    <img
                      src={image5}
                      alt="img"
                      style={{ height: "300%", width: "100%" }}
                    />
                    <p className="descStyle">
                      Receive an email notification featuring the top three
                      marketing outlets carefully selected to fulfill the
                      optimal promotional needs of your business.
                    </p>
                  </Card>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xxl={5}
                style={{ marginBottom: "20px" }}
              >
                <div className="cardStyle">
                  <Card className="custom-card">
                    <h2 className="titleStyle">
                      <b>UNLOCK THE POSSIBILITIES</b>
                    </h2>
                    <img
                      src={image4}
                      alt="img"
                      style={{ height: "300%", width: "100%" }}
                    />
                    <p className="descStyle">
                    Once our algorithm identifies your 
                    top three matches, you can access a 
                    comprehensive list of marketing outlets ready to 
                    elevate your efforts. Connect directly with them or 
                    opt to have a representative reach out to you.
                    </p>
                  </Card>
                </div>
              </Col>
            {/* </div> */}
            </Col>
          </Row>

          <Row>
            <Col
              //  span={12}
              xxl={{ span: 20, offset: 1 }}
              xl={{ span: 22, offset: 0 }}
              lg={{ span: 23, offset: 1 }}
              md={{ span: 20, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <div className="media-home">
                <p className="point-head1">
                  Does your business provide marketing and advertising services?
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 14, offset: 0 }}
              style={{ marginTop: "-6%",marginBottom:'5%' }}
            >
              <div className="vedio-home">
                <video className="vedio-1" controls>
                  <source src={video1} type="video/mp4" />
                </video>
              </div>
            </Col>
            <Col
              xl={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
              xxl={{ span: 9, offset: 0 }}
            >
              <div
                className="point-1"
                style={{ marginTop: "-1vw", marginLeft: "25px" }}
              >
                <p className="para2-home">
                If so, MediaPiq can connect you with businesses or non-profit
                organizations seeking your services. We take care of the
                groundwork by generating qualified leads. Simply create a
                business profile on MediaPiq, sharing details about your
                platform such as demographics of your audience, traffic and
                circulation information, content types, and pricing packages –
                everything a potential advertiser might want to know. Adding
                your profile to our database is free! Once our algorithm
                identifies a potential match with an advertiser, you'll receive
                an email with instructions to establish the connection. That's
                all it takes! <br />
                Watch the video to see how it works.</p> <br />               
                <div>
                <Link to="/app/register">
                  <Button
                    key="1"
                    type="primary"
                    className="btnStyle"                    
                  >
                    Join As a Media Outlet
                  </Button>
                </Link>
                </div>
              
              </div>
            
            </Col>
          </Row>
        </Row>

        <div className="footer">
          <Footer className="ant-layout-footer">
            <Row>
              <Col
                xl={{ span: 6, offset: 2 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 6, offset: 2 }}
                xs={{ span: 8, offset: 0 }}
                sm={{ span: 8, offset: 0 }}
              >
                <Link to="/app/Privacypolicy">
                  <p
                    className="link"
                    // onClick={() => this.openInNewTab("/app/Privacypolicy")}
                  >
                    Privacy Policy
                  </p>
                </Link>
              </Col>
              <Col
                xl={{ span: 5, offset: 3 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 6, offset: 2 }}
                xs={{ span: 7, offset: 1 }}
                sm={{ span: 7, offset: 1 }}
              >
                <Link to="/app/TermsofServices">
                  <p
                    className="link"
                    // onClick={() => this.openInNewTab1("/app/TermsofServices")}
                  >
                    Terms Of Service
                  </p>
                </Link>
              </Col>
              <Col
                xl={{ span: 6, offset: 2 }}
                lg={{ span: 6, offset: 2 }}
                md={{ span: 6, offset: 2 }}
                xs={{ span: 3, offset: 4 }}
                sm={{ span: 3, offset: 4 }}
              >
                <Link to="/app/Disclaimer">
                  {" "}
                  <p
                    className="link"
                    // onClick={() => this.openInNewTab2("/app/Disclaimer")}
                  >
                    Disclaimer
                  </p>
                </Link>
              </Col>
            </Row>
            <p
              style={{ color: "white", textAlign: "center", marginTop: "1vw" }}
            >
              2024, Mediapiq LLC. All rights reserved.
            </p>
          </Footer>
        </div>
      </Layout>
    );
  }
}
export default Home;
