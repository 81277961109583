import React, { Component } from "react";
// import "antd/dist/antd.min.css";
// import "antd/dist/antd.min.css";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Modal,
  // notification,
  // message,
} from "antd";
// import { geocodeByAddress } from "react-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
// import AutoComplete from "../Components/Autocomplete";
import { doRegistration } from "../../Api/RegistrationApis";
import LocationAutocomplete from "../Autocomplete";
// import { withAppContext } from "../store/contextStore";
import NumberFormat from "react-number-format";
import ".././index.css";
import Amplify from "aws-amplify";
import AppConfig from "../config/AppConfig";
import { getUser, verifyName } from "../../Api/Apis";
import { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: `${AppConfig.APP_IDENTITY_POOL_ID}`,
    // REQUIRED - Amazon Cognito Region
    region: `${AppConfig.APP_COGNITO_REGION}`,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${AppConfig.APP_COGNITO_USER_POOL_ID}`,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: `${AppConfig.APP_CLIENT_ID}`,
  },
});

// const Search = Input.Search;
// const { getFieldDecorator } = this.props.form;
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BusinessName: "",
      selectedAddress: "",
      selectedAddress1: props.data.serviceArea ? props.data.serviceArea : [],
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      Contactperson: "",
      loading: false,
      agreement: "",
      latLangs: {},
      visable: false,
      area: "",
      username: "",
      cities: "",
      Address: "",
      Address2: [],
      password: "",
      user: "",
      autocity: "",
      autostate: "",
      autozip: "",
      address2: "",
      selectedAddress2: "",
      item1: "",
      editMode: false,
      getLoginData: false,
      defaultEmptyAddress: true,
      loginAddress: "",
      singInProps: false,
      showAddress: [],
      cityValue: "",
      stateValue: "",
      zipCodeValue: "",
      citySearch: [],
    };
  }
  componentDidMount = () => {
    this.setState({
      Address2:
        this.props.data.serviceArea && this.props.data.serviceArea.length > 0
          ? this.props.data.serviceArea
          : this.props.data2.serviceArea &&
            this.props.data2.serviceArea.length > 0
          ? this.props.data2.serviceArea
          : [],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      [
        "name",
        "address",
        "city",
        "state",
        "code",
        "person",
        "email",
        "contact",
        "service",
      ],
      (err, values) => {
        if (!err) {
          this.setState({
            agreement: values.agreement,
          });

        
          let oldAddress =
            this.state.selectedAddress?.length > 0
              ? this.state.selectedAddress
              : this.props.data.address;

          let data = {
            businessName: values["name"],
            // address: values["address"],
            address: oldAddress,
            city: values["city"],
            place: values["state"],
            zip: values["code"],
            phoneNumber: values["person"],
            email: values["email"],
            contactPerson: values["contact"],
            username: values["username"],
          };
          // console.log("data", data);

          this.addUser(values["email"], data);
        }
      }
    );
  };
  addUser = async (email, regData) => {
    this.setState({ loading: true });
    console.log(regData, "______________________________",this.state.user);
    if (this.state.singInProps) {
      this.props.handleInputs(regData, "/app/Area");
    }
    if (
      this.props.isRegister !== "doneRegistration" &&
      !this.state.singInProps
    ) {
      this.saveRegistrationData(regData);
    } else {
      this.props.handleInputs(regData,"/app/Area");
    }
    // if (this.state?.user !== ""){
    //   this.saveRegistrationData(regData);
    // } else {
    //   this.props.handleInputs(regData, "/app/Area");
    // }
  };
  saveRegistrationData = async (data) => {
    const Data = {
      entityData: data,
    };

    doRegistration(AppConfig["entityNames"]["registrations"], Data).then(
      (result) => {
        console.log(result,"result.sss")
        // localStorage.setItem("step", JSON.stringify(Data));
        if (result.success) {
          if (result.data.statusCode === 0) {
            alert(result.data.message);
            // console.log(result.data.message);            
            this.setState({
              loading: false,
            });
          return;
          }
          if (result.data.code === "UsernameExistsException") {
            console.log(result.data.code,"codeeeee")
            alert(result.data.message);
            this.setState({
              loading: false,
            });
          return;
          }

          // console.log(result.data.data.id);
          localStorage.setItem("id", JSON.stringify(result.data.data.id));
          this.handleLogin(Data["entityData"]["email"], result.data["password"], "registration", data);          
        } else if (result.err) {
          alert("your registration failed ");
          // console.log(data);
          this.setState({ loading: true });

          // this.props.history.push("/app/Area");
        }
       
      }
    );
  };
  setPhoneNumber = (value, id) => {
    this.props.form.setFieldsValue({
      [id]: value,
    });
  };
  // onSelect(value) {
  //   console.log("onSelect", value);
  // }
  handleSearch = (value) => {
    this.setState({
      dataSource: !value ? [] : [value, value + value, value + value + value],
    });
  };

  getAddress = (address, latLng, fieldId) => {
    this.props.form.setFieldsValue({
      [fieldId]: address,
    });
    let latLangs = this.state.latLangs;
    latLangs[fieldId] = latLng;
    this.setState({
      latLangs,
    });
    // console.log(address);
  };
  hadnleServicearea = (results) => {
    // console.log(results, "////////////////");

    let Address2 = this.state.Address2.length > 0 ? this.state.Address2 : [];
    Address2.push(results.formatted_address);
    // console.log(Address2);
    this.setState({ Address2, editMode: true });

    // {localStorage.setItem(
    //   "item",
    //   JSON.stringify(item1.address)
    // )}
  };
  click = () => {
    this.setState({
      editMode: false,
    });
  };
  handleState = (results) => {
    // console.log(results, "////////////////");
    // console.log(results.formatted_address);
    this.setState({
      selectedAddress: results.formatted_address,
    });
    localStorage.setItem("address", JSON.stringify(results.formatted_address));
    results.address_components.forEach((data) => {
      if (data["types"][0] === "locality") {
        // console.log(data, "hhh");
        this.setState({
          autocity: data.long_name,
        });
      }
      if (data["types"][0] === "postal_code") {
        // console.log(data, "hhhs");
        this.setState({
          autozip: data.long_name,
        });
      }
      if (data["types"][0] === "administrative_area_level_1") {
        // console.log(data, "hhhs");
        this.setState({
          autostate: data.long_name,
        });
      }
    });
  };
  showModal = (e) => {
    // console.log("hi");
    e.preventDefault();
    this.setState({
      visible: true,
    });
  };
  hideModal = () => {
    this.setState({
      visible: false,
    });
  };
  handleSignin = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["username", "password"],
      (err, values) => {
        // console.log(values);
        if (!err) {
          this.handleLogin(values["username"], values["password"]);
          this.setState({
            agreement: values.agreement,
          });
          this.setState({
            loading: true,
          });
        }
      }
    );
  };
  handleLogin = async (Username, Password, callFrom=null, regData={}) => {
    console.log(callFrom, regData)
    // this.setState({
    //   loading: true,
    // });
    try {
      console.log(Username, Password, "llllllllllll");

      const user = await Auth.signIn(Username, Password);
      console.log(user);
      localStorage.setItem("user", JSON.stringify(user.username));
      // let id = JSON.parse(localStorage.getItem("id"));
      // this.setState({
      //   loading: true,
      // });
      // console.log(user.username);
      getUser(AppConfig["entityNames"]["registrations"], user.username).then(
        (result) => {
          if (result.success) {
            console.log(result.data);
            localStorage.setItem("userid", JSON.stringify(result.data.id));
            localStorage.setItem("loginDetails", JSON.stringify(result.data));
            this.usersdata(result.data);
            this.setState({
                loading: false,
              });
          }
        }
      );
      this.usersdata = async (user) => {
        // console.log(data)
        // this.setState(user);
        // console.log(user);
        this.setState({
          loginAddress: user.address,
          user,
          defaultEmptyAddress: false,
          Address2: user.serviceArea,
          singInProps: true,
        });
        if(callFrom==="registration"){
          this.props.handleInputs(regData, "/app/Area");
        } else {
          this.props.handleSigninData(user, "/app/Area");
        }
      
      };
      this.setState({
        visible: false,
      });
      // console.log(this.state.user);
    } catch (error) {
      console.log("error signing in", error);
      alert(error)
    }
  };

  handleVerify = (event) => {
    let bu = "";
    this.props.form.validateFieldsAndScroll(["name"], (err, values) => {
      // console.log(values);
      if (!err) {
        // console.log(values);
        bu = values["name"];
      }
    });
    event.preventDefault();
    // console.log(bu);

    let data = {
      businessName: bu,
    };
    if (bu === "") {
      alert("Please enter your Business name ");
    } else {
      verifyName(AppConfig["entityNames"]["registrations"], data).then(
        (res) => {
          if (res.data.statusCode === 0) {
            alert(res.data.message);
           
          } else {
            alert("Business name doesn't exist, please sign up");
          }
        }
      );
    }
    this.setState({
      loading: false,
    });
  };
  // handleAddress = (address) => {
  //   let newAddress = {
  //     address,
  //   };
  // };

  handlePhone = (e) => {
    let phoneNumber = e.target.value.replace(/[^+0-9]/g, "").length;
    this.setState({
      phone: phoneNumber,
    });
  };

  handleSingleSearch2 = (address) => {
    const Address2 = this.state.Address2 ? this.state.Address2 : [];
    // Address2.push(address.label);

    // console.log(address.label, "address.label,", address);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let newLocations = [];

    geocodeByAddress(address.label)
      .then((results) => {
        // console.log(results, ";;;;");
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }

        const stateCodes = [
          {
            stateName: "Connecticut",
            stateType: "CT",
          },
        ];
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );

        // let setVendor =

        if (setVendor && setVendor.length > 0) {
          // console.log(address, "address.label", address.label, Address2);
          Address2.push(results[0]["formatted_address"]);

          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);

          this.setState({
            Address2,
          });
          // this.setState({
          //   showAddress: address,
          //   cityValue,
          //   stateValue,
          //   zipCodeValue,
          // });
        } else {
          alert(
            "We are currently serving businesses only in Connecticut ,\nplease select an CT address."
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  
  handleSingleSearch = (address) => {
    const citySearch = [];
    citySearch.push(address.label);
    this.setState({
      citySearch,
    });
    // console.log(address.label);
    let cityValue = "";
    let stateValue = "";
    let zipCodeValue = "";
    let newLocations = [];
    let address2 = address.label;
    this.setState({
      Address: address2,
    });
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
          if (address[i].types[0] === "postal_code") {
            zipCodeValue = address[i].long_name;
          }
        }

        // console.log("stateValue stateValue", stateValue);

        const stateCodes = [
          {
            stateName: "Connecticut",
            stateType: "CT",
          },
        ];
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );

        // let setVendor =

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);

          this.setState({
            showAddress: address2,
            cityValue,
            stateValue,
            zipCodeValue,
          });
        } else {
          alert(
            "We are currently serving businesses only in Connecticut ,\nplease select an CT address."
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };
  onSelect = (address) => {
    let cityValue = "";
    let stateValue = "";
    let newLocations = this.state.cities.searchLocation;
    geocodeByAddress(address.label)
      .then((results) => {
        let address = results[0].address_components;
        for (let i = 0; i < address.length; i++) {
          if (address[i].types[0] === "locality") {
            cityValue = address[i].long_name;
          }
          if (address[i].types[0] === "administrative_area_level_1") {
            stateValue = address[i].short_name;
          }
        }

        const stateCodes = [
          {
            stateName: "Connecticut",
            stateType: "CT",
          },
        ];
        let setVendor = stateCodes.filter(
          (restState) =>
            restState.stateName.toLowerCase() === stateValue.toLowerCase() ||
            restState.stateType.toLowerCase() === stateValue.toLowerCase()
        );

        if (setVendor && setVendor.length > 0) {
          let newSearchLocation = {
            cityName: cityValue,
            stateName: stateValue,
            locationType: "location",
          };
          newLocations.push(newSearchLocation);
          this.setState({
            cities: {
              searchLocation: newLocations,
              type: "location",
              citiesList: [
                ...this.state.cities.citiesList,
                `${stateValue}:${cityValue}`,
              ],
            },
          });
        } else {
          alert(
            "We are currently serving businesses only in Connecticut ,\nplease select an CT address."
          );
        }
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    // console.log(this.state.Address2, "this.state.Address2.");
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;
    const {
      loginAddress,
      // cityValue,
      autocity,
      autostate,
      autozip,
      // stateValue,
      // showAddress,
      // zipCodeValue,
      // citySearch,
      // Address2,
    } = this.state;
    console.log(this.state.user, "++++++++++++++++");
    // console.log(this.props.data.address);
    // console.log(this.props.data2.serviceArea);
    return (
      <>
        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Row>
            <Row>
              <Col span={24} style={{ textAlign: "center", marginLeft: "-3%" }}>
                <h3>
                If your business is already registered with us, please 
                <a
                    href="/#"
                    onClick={this.showModal}
                    style={{ color: "#000000", padding: "5px" }}
                  >
                     {" "}
                <b>click here</b></a> to sign in.                
                </h3>
              </Col>
            
            </Row>

            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 12, offset: 6 }}
              >
                {" "}
                <h4>
                  <b>Business Name</b>
                </h4>
                <Form.Item className="Formitem" name="name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Business Name!",
                      },
                    ],
                    initialValue:
                      data.businessName || this.state.user.businessName,
                  })(
                    <Input
                      className="input_width1"
                      style={{
                        borderRadius: "50px",

                        height: "40px",
                        padding: "10px",
                      }}
                    />
                  )}
                  <span
                    onClick={this.handleVerify}
                    style={{
                      color: "#165874",
                      cursor: "pointer",
                      padding: "10px",
                    }}
                  >
                    <b> Verify</b>
                  </span>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 12, offset: 6 }}
              >
                {" "}
                <h4>
                  <b>Address</b>
                  <span
                    style={{
                      marginLeft: "5px",
                      color: "#165874",
                      fontSize: "12px",
                    }}
                  >
                    <b>
                      {" "}
                      *We are currently serving businesses only in Connecticut
                    </b>
                  </span>
                </h4>
                {loginAddress && (
                  <Form.Item
                    className="input_width"
                    name="address"
                    style={{
                      paddingTop: "2px",
                      borderRadius: "50px",
                    }}
                  >
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          required: false,
                          message: "Please input your Address!",
                        },
                      ],
                      defaultValue:
                        (data && data.address) || this.state.user.address,
                    })(
                      // <GooglePlacesAutocomplete
                      //   className="addresinput"
                      //   apiKey={process.env.REACT_APP_MAPS_KEY}
                      //   autocompletionRequest={{
                      //     // types: ["(cities)"],
                      //     componentRestrictions: {
                      //       country: ["us"],
                      //     },
                      //   }}
                      //   selectProps={{
                      //     defaultInputValue: loginAddress,
                      //     citySearch,
                      //     placeholder: "Search Address...",
                      //     onChange: (address) => {
                      //       this.handleSingleSearch(address);
                      //     },
                      //   }}
                      // />
                      <LocationAutocomplete
                        getAddress={(address, latLng) =>
                          this.getAddress(address, latLng, "address")
                        }
                        address={this.state.selectedAddress}
                        editModeAuto={true}
                        setAddress={(address) => {
                          this.setState({ selectedAddress: address });
                        }}
                        // initialValues={data?.address}
                        defaultValue={loginAddress}
                        getNewAddress={this.handleState}
                        className="ant-input"
                        // defaultValue ={() => this.state.user.address}
                      />
                    )}
                  </Form.Item>
                )}
                {loginAddress === "" && (
                  <Form.Item
                    name="address"
                    className="input_width"
                    style={{ borderRadius: "50px" }}
                  >
                    {getFieldDecorator("address", {
                      rules: [
                        {
                          required:
                            this.props?.data2?.address !== "" ? false : true,
                          message: "Please input your Address!",
                        },
                      ],
                      initialValue:
                        (data && data.address) || this.state.user.address,
                    })(
                      // <GooglePlacesAutocomplete
                      //   className="form-input"
                      //   apiKey={process.env.REACT_APP_MAPS_KEY}
                      //   autocompletionRequest={{
                      //     componentRestrictions: {
                      //       country: ["us"],
                      //     },
                      //   }}
                      //   selectProps={{
                      //     citySearch,
                      //     defaultInputValue:
                      //       this.props.data2.address || this.props.data.address,

                      //     placeholder: "Search Address...",
                      //     onChange: (address) => {
                      //       this.handleSingleSearch(address);
                      //     },
                      //   }}
                      //   style={{ width: "400px" }}
                      // />
                      <LocationAutocomplete
                        getAddress={(address, latLng) =>
                          this.getAddress(address, latLng, "address")
                        }
                        address={this.state.selectedAddress}
                        editModeAuto={true}
                        setAddress={(address) => {
                          this.setState({ selectedAddress: address });
                        }}
                        // initialValues={data?.address}
                        getNewAddress={this.handleState}
                        className="ant-input"
                        defaultValue={
                          this.props.data.address || this.props.data2.address
                        }
                      />
                    )}
                  </Form.Item>
                )}
              </Col>
            </Row>

            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                lg={{ span: 10, offset: 7 }}
                md={{ span: 12, offset: 6 }}
              >
                <h4>
                  <b>City</b>
                </h4>
                <Form.Item className="Formitem" name="city">
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                    ],
                    initialValue:
                      autocity || (data && data.city) || this.state.user.city,
                  })(
                    <Input
                      className="input_width1"
                      style={{
                        borderRadius: "50px",
                        height: "40px",
                        padding: "10px",
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                lg={{ span: 10, offset: 7 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                md={{ span: 12, offset: 6 }}
                className="input_width_state"
              >
                <Col
                  xl={{ span: 11 }}
                  lg={{ span: 11 }}
                  xs={{ span: 11 }}
                  sm={{ span: 11 }}
                  md={{ span: 11 }}
                >
                  <h4>
                    <b>State</b>
                  </h4>
                  <Form.Item className="Formitem" name="state">
                    {getFieldDecorator("state", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your State!",
                        },
                      ],
                      initialValue:
                        autostate ||
                        (data && data.place) ||
                        this.state.user.place,
                    })(
                      <Input
                        // size="large"
                        style={{
                          borderRadius: "50px",
                          // width: "200px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 11, offset: 2 }}
                  lg={{ span: 11, offset: 2 }}
                  xs={{ span: 11, offset: 1 }}
                  sm={{ span: 11, offset: 1 }}
                  md={{ span: 11, offset: 1 }}
                >
                  <h4>
                    <b>Zip Code</b>
                  </h4>
                  <Form.Item className="Formitem" name="code">
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: "Please input your Zip Code!",
                        },
                      ],
                      initialValue:
                        autozip || (data && data.zip) || this.state.user.zip,
                    })(
                      <Input
                        // size="large"
                        style={{
                          borderRadius: "50px",
                          // width: "200px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                      // <Input
                      //    size="large"
                      //    style={{
                      //     borderRadius: "50px",

                      //   }}
                      // />
                    )}
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 8 }}
                sm={{ span: 22, offset: 1 }}
                xs={{ span: 22, offset: 1 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 10, offset: 7 }}
              >
                <h4>
                  <b>Contact person name</b>
                </h4>
                <Form.Item className="Formitem" name="contact">
                  {getFieldDecorator("contact", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Contact person phone!",
                      },
                    ],
                    initialValue:
                      (data && data.contactPerson) ||
                      this.state.user.contactPerson,
                  })(
                    <Input
                      className="input_width1"
                      // className="input-a"
                      style={{
                        borderRadius: "50px",
                        // width: "400px",
                        height: "40px",

                        // padding: "10px",
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Row>
                <Col
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  md={{ span: 12, offset: 6 }}
                  lg={{ span: 10, offset: 7 }}
                >
                  <h4>
                    <b>Contact person email</b>
                  </h4>
                  <Form.Item className="Formitem" name="email">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          type: "email",
                          message: "This is not a valid Email Id",
                        },
                        {
                          required: true,
                          message: "Please input contact person email ID!",
                        },
                      ],
                      initialValue:
                        (data && data.email) || this.state.user.email,
                    })(
                      <Input
                        className="input_width1"
                        style={{
                          borderRadius: "50px",
                          // width: "400px",
                          height: "40px",
                          padding: "10px",
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  md={{ span: 12, offset: 6 }}
                  lg={{ span: 10, offset: 7 }}
                >
                  <h4>
                    <b>Contact person phone</b>
                  </h4>
                  <Form.Item className="Formitem" name="person">
                    {getFieldDecorator("person", {
                      rules: [
                        {
                          required: true,

                          message: "Please input Valid phone number",
                        },
                      ],
                      initialValue:
                        (data && data.phoneNumber) ||
                        this.state.user.phoneNumber,
                    })(
                      <NumberFormat
                        className="input_width"
                        format="+1 (###) ###-####"
                        style={{
                          borderRadius: "50px",
                          // width: "400px",
                          height: "40px",
                          padding: "10px",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                        onChange={this.handlePhone}
                      />
                    )}
                    <div>
                      {this.state.phone > 1 && this.state.phone < 12 && (
                        <span style={{ color: "red" }}>
                          This is not a valid phoneNumber
                        </span>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ span: 12, offset: 6 }}
                  xl={{ span: 8, offset: 8 }}
                  sm={{ span: 22, offset: 1 }}
                  xs={{ span: 22, offset: 1 }}
                  lg={{ span: 10, offset: 7 }}
                  className="input_width"
                  style={{ textAlign: "center" }}
                >
                  <Form.Item>
                    {/* <Link to="/app/Area"> */}
                    <Button
                      key="1"
                      type="primary"
                      loading={this.state.loading}
                      htmlType="submit"
                      className="signup-button"
                      style={{
                        borderRadius: "50px",
                        backgroundColor: "#dce7f0",
                        color: "#004b84",
                        width: "150px",
                        border: "none",
                        fontWeight: "bold",
                        height: "40px",
                        marginTop: "30px",
                        marginLeft: "20%",
                      }}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Row>
        </Form>
        <>
          <Form
            onSubmit={this.handleSignin}
            style={{ marginBottom: "20%" }}
            id="form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Modal
              title="SIGN-IN"
              visible={this.state.visible}
              onCancel={this.hideModal}
              footer={[
                <div style={{ height: "35px" }}>
                  {/* <Button type="primary" form="form" style={{ float: "left" }}>
                    Forget password
                  </Button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                    form="form"
                    style={{ float: "right" }}
                    on
                  >
                    Log in
                  </Button>
                </div>,
              ]}
            >
              <Form.Item label="Username :" name="username">
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ],
                })(<Input className="input_modal" />)}
              </Form.Item>

              <Form.Item label="Password " name="password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ],
                })(
                  <Input.Password
                    className="input_password"
                    // style={{ borderRadius: "50px", padding: "4px 0px" }}
                  />
                )}
              </Form.Item>
              <div>
                <h4>
                  Username & password has be sent to your registered email ID.
                </h4>
              </div>
              <Form.Item name="submit"></Form.Item>
            </Modal>
          </Form>
        </>
      </>
    );
  }
}
export default Form.create({ name: "Signup" })(Signup);
