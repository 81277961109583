export default {
  date_format: "MM/DD/YYYY",
  date_time_format: "MM/DD/YYYY hh:mm A",
  time_format: "hh:mm A",
  dateTimeFormat: "MMM DD, YYYY hh:mm A",
  APP_S3_REGION: process.env.REACT_APP_REGION,
  APP_S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
  API_GATEWAY: process.env.REACT_APP_API_GATEWAY,
  APP_COGNITO_REGION: process.env.REACT_APP_REGION,
  APP_COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  APP_IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  fetch_refresh_time: 30000,

entityNames: {
  registrations :"registrations",
  getCompanies:"getCompanies",
  verifyBusiness:"verifyBusiness",
  questionnaires:"questionnaires",
  contacts:"contacts",
  payment:"payment",
  sendMail:"sendMail",
  checkUser:"checkUser",
  getQuestion:"getQuestion",
  parseXml:'parseXml',
},
addUser :"addUser",
addQuestion: "addQuestion",

}
