import React, { Component } from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Card, Input } from "antd";
import { StripeProvider } from "react-stripe-elements";
import NumberFormat from "react-number-format";
import Leadscard from "./Leadscard";
export default class Leadspayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [
        { code: "123", discount: 5 },
        { code: "456", discount: 10 },
        { code: "7854", discount: 15 },
        { code: "4598", discount: 20 },
      ],
      discount: 0,
      promoCode: "",
      promoCodeApplied: false,
      goals: this.props.location.state,
    };
  }
  // componentDidMount=()=>{
  //     this.props.saveCompaniesData()
  // }
  checkPromoCode = (e) => {
    let promoDiscount = this.state.codes.filter(
      (c) => c.code === this.state.promoCode
    );
    // console.log(promoDiscount, "promoDiscount");
    if (promoDiscount.length > 0) {
      alert("Promo code applied successfully");
      this.setState({
        discount: promoDiscount[0]["discount"],
        promoCodeApplied: true,
      });
    } else {
      alert("Invalid promo code");
      this.setState({
        discount: 0,
        promoCodeApplied: false,
      });
    }
  };
  removePromoCode = () => {
    let codes = this.state.codes;
    codes.map((order) => {
      order["paymentMethod"] = "card";
      return order;
    });
    this.setState({
      promoCodeApplied: false,
      promoCode: "",
      discount: 0,
    });
  };
  //   gotoMatch = () => {
  //     this.props.gotoMatch();
  //   };

  render() {
    console.log(this.props.location.state, "promo");
    // console.log(this.props)
    console.log(this.state.goals, "this.state.goals");
    // console.log(data,"data")

    // const { formData } = this.props;
    let subTotal = 0;
    let total = 0;

    // console.log('formData',formData.rangeGoals)
    subTotal = this.state.goals.map((e) => 
      e.rangeGoals === "$10K-$25K"
        ? 100
        : e.rangeGoals === "$26K-$35K"
        ? 150
        : e.rangeGoals === "$36K-$50K"
        ? 150
        :e.rangeGoals === "$51K+"
        ? 200 :10
    );
    
    console.log("sub", subTotal);
    let discount = subTotal[0] * (this.state.discount / 100);
    total = subTotal[0] - discount;
    //  alert(total)

    // console.log(total, "total");
    return (
      <div>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginTop: "100px" }}>
              <h1 style={{ fontWeight: "bold" }}>Payment Process</h1>
            </div>
          </Col>
          <Col
            xxl={{ span: 16, offset: 8 }}
            xl={{ span: 16, offset: 8 }}
            lg={{ span: 16, offset: 8 }}
            md={{ span: 16, offset: 8 }}
            sm={{ span: 16, offset: 3 }}
            xs={{ span: 11, offset: 3 }}
            style={{ zIndex: "1" }}
          >
            <div className="testSummary">
              <h3 className="summary">Summary</h3>

              <div className="TotalAmt">
                {" "}
                Sub Total{" "}
                <span className="testsCost">
                  <NumberFormat
                    value={subTotal[0]}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </div>
              <div className="TotalAmt">
                {" "}
                Discount{" "}
                <span className="testsCost">
                  <NumberFormat
                    value={this.state.discount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    suffix={"%"}
                  />
                </span>
              </div>
              <div className="TotalAmt">
                {" "}
                Total{" "}
                <span className="testsCost">
                  <NumberFormat
                    value={total}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </span>
              </div>
              <div className="TotalAmt">
                <div>
                  <Row>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      Promo Code
                    </Col>
                    <Col
                      lg={8}
                      md={8}
                      sm={8}
                      xs={8}
                      style={{ marginTop: "-8px" }}
                    >
                      {/* <span className="testsCost"> */}
                      <Input
                        // type="text"
                        name="promoCode"
                        // id="promoCode"
                        onChange={(e) =>
                          this.setState({
                            promoCode: e.target.value,
                          })
                        }
                        value={this.state.promoCode}
                        // readOnly={promoCodeApplied}
                      />
                      {/* </span> */}
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      {!this.state.promoCodeApplied ? (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#20a8d8",
                            float: "right",
                          }}
                          onClick={() => this.checkPromoCode()}
                        >
                          Apply
                        </span>
                      ) : (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#20a8d8",
                            float: "right",
                          }}
                          onClick={() => {
                            this.setState({ promoCode: "" });
                            this.removePromoCode();
                          }}
                        >
                          Remove
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col span={24} style={{ marginTop: "1vw" }}>
            <StripeProvider apiKey="pk_test_51LSfgsIlY9KGbjYfUeZ6tqu0otOOIuKEKPXa2uEc6YNOoXSBrc49KyLF0vzIL5Dolr7yr3YOARZDQlXOr8W2qrpF00txnvTR8Y">
              <Leadscard
                amount={total}
                goalsCard={this.state.goals}
                // match={() => this.props.gotoMatch()}
                // formData={this.props.formData}
              />
            </StripeProvider>
          </Col>
        </Row>
      </div>
    );
  }
}
