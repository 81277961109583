import React from "react";
import "antd/dist/antd.min.css";
import { Row, Col, Form, Button, Progress } from "antd";
import { update } from "../../Api/Apis";
import AppConfig from ".././config/AppConfig";
class Marketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInternet: "",
      selected: [],
      selectedRowKeys: [],
      secondstep: "",
      checked: false,
      isdeselect: false,     

      marketing: [
        "Public relations",
        "Brand management",
        "Print marketing",
        "Event marketing",
        "Outdoor",
        "Broadcast marketing on television",
        "Broadcast marketing on radio",
        "Media buys",
        "Content creation",
        "Video production",
        "Audio production",
        "Internet marketing",
      ],
    };
  }

  componentDidMount = () => {
    const selected = this.props.marketdata && this.props.marketdata.length > 0
      ? this.props.marketdata
      : this.props.data2.marketing && this.props.data2.marketing.length > 0
      ? this.props.data2.marketing
      : [];
    this.setState({ selected });
  };

  handleClick = (e) => {
    const { value } = e.target;
    const { selected } = this.state;

    // Toggle selection
    if (selected.includes(value)) {
      this.setState({ selected: selected.filter((item) => item !== value) });
    } else {
      this.setState({ selected: [...selected, value] });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { selected } = this.state;

    if (selected.length === 0) {
      alert("Please select your options!");
      return;
    }

    this.setState({ loading: true });

    const selectedData = selected.length ? selected : this.state.selectedInternet;
    const path = selectedData === "Internet marketing" ? "/app/Internetmarketing" : "/app/Platform";

    this.saveRegistrationData(selectedData, path);
  };

  saveRegistrationData = async (selected, path) => {
    this.props.handleInputs(selected, path);
    const data = { marketing: selected };
    const id = JSON.parse(localStorage.getItem("id")) || JSON.parse(localStorage.getItem("userid"));

    const Data = { entityData: data };
    update(AppConfig["entityNames"]["registrations"], id, Data).then((result) => {
      if (result.success) {
        localStorage.setItem("user", JSON.stringify(result.data.data.id));
      } else if (result.err) {
        alert("Your registration failed");
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { marketing, selected } = this.state;

    return (
      <Row>
        <div className="progress-mobile">
          <Progress percent={this.props.count * 10} size="small" status="active" />
        </div>
        <Col span={24}>
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            <h1 style={{ fontWeight: "bold" }}>
              What type of marketing/advertising services do you offer?
            </h1>
          </div>
        </Col>

        <Col span={24}>
          <Form.Item className="Formitem">
            <p style={{ color: "gray", textAlign: "center" }}>Select 1 or more</p>
          </Form.Item>
        </Col>

        <Form onSubmit={this.handleSubmit} style={{ marginBottom: "10%" }}>
          <Col lg={12} md={12} sm={24} xs={24} className="service-area">
            <Form.Item className="Formitem">
              {marketing.map((data, index) => (
                <div
                  key={index}
                  style={{
                    width: "max-content",
                    float: "left",
                    marginLeft: "10px",
                  }}
                >
                  <label>
                    <input
                      name={data}
                      type="checkbox"
                      value={data}
                      onChange={this.handleClick}
                      className="visually-hidden"
                      checked={selected.includes(data)}
                    />
                    <div className="btn">{data}</div>
                  </label>
                </div>
              ))}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                type="primary"
                loading={this.state.loading}
                onClick={() =>
                  this.props.handlePrevious("/app/Area", "doneRegistration", this.props.data)
                }
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  marginRight: "10px",
                  float: "right",
                  fontWeight: "bold",
                  height: "40px",
                  marginBottom: "80px",
                }}
              >
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="Formitem">
              <Button
                type="primary"
                loading={this.state.loading}
                htmlType="submit"
                className="Submit"
                style={{
                  borderRadius: "50px",
                  backgroundColor: "#dce7f0",
                  color: "#004b84",
                  width: "150px",
                  border: "none",
                  fontWeight: "bold",
                  height: "40px",
                }}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
    );
  }
}

export default Marketing;
